import React, { useContext, useEffect } from 'react'
import { graphql } from 'gatsby'
import { Layout, Banner, Ticker } from '@components'
import BlockWrapper from '@pageBuilder/wrapper'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import ThemeContext from '@context/themeContext'

const Page = ({ data, pageContext }) => {
  // const { isWeCareNow } = useContext(ThemeContext)
  const {
    moduleArea,
    seoMetaTags,
    heading,
    caption,
    image,
    button,
    ticker,
    theme,
    disableMenuClick
  } = data.page

  if (disableMenuClick) {
    if (typeof window !== 'undefined') {
      window.location.href = '/'
    }
  }

  const themeScheme = theme?.length ? theme[0].scheme : false

  return (
    <Layout isWecareNowPage={pageContext?.isWecareNowPage}>
      <HelmetDatoCms defer={false} seo={seoMetaTags} />
      {ticker && <Ticker items={ticker} />}
      {(heading || caption || image) && (
        <Banner
          image={image}
          heading={heading}
          caption={caption}
          button={button}
          theme={themeScheme}
          contentBackground
          contentAlignment={'left'}
          contentBackgroundColor={themeScheme ? themeScheme : 'primary'}
          breadcrumbs={pageContext?.breadcrumbs}
        />
      )}
      <BlockWrapper blocks={moduleArea} theme={themeScheme} />
    </Layout>
  )
}

export const query = graphql`
  query pageById($id: String!) {
    page: datoCmsPage(id: { eq: $id }) {
      id
      path
      slug
      title
      disableMenuClick
      image {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "613", fit: "crop", w: "950", q: 60 }
        )
        alt
      }
      theme {
        id
        scheme
      }
      ticker {
        text
        link {
          ...Link
        }
      }
      heading
      caption
      button {
        ...Link
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      moduleArea {
        ... on DatoCmsModuleAreaWithAside {
          ...ModuleAreaWithAside
        }
        ... on DatoCmsPageCardGrid {
          ...PageCardGrid
        }
        ... on DatoCmsBookingButton {
          ...BookingButton
        }
        ... on DatoCmsPathwayCardGrid {
          ...PathwayCardGrid
        }
        ... on DatoCmsListWithContent {
          ...ListWithContent
        }
        ... on DatoCmsClinicCardGrid {
          ...ClinicCardGrid
        }
        ... on DatoCmsTeamMemberCardGrid {
          ...TeamMemberCardGrid
        }
        ... on DatoCmsImageWithContent {
          ...ImageWithContent
        }
        ... on DatoCmsSimpleCardGrid {
          ...SimpleCardGrid
        }
        ... on DatoCmsImage {
          ...Image
        }
        ... on DatoCmsContent {
          ...Content
        }
        ... on DatoCmsBlockHeader {
          ...BlockHeader
        }
        ... on DatoCmsVideo {
          ...Video
        }
        ... on DatoCmsFeatureGrid {
          ...FeatureGrid
        }
        ... on DatoCmsButton {
          ...Button
        }
        ... on DatoCmsAilmentsGrid {
          ...AilmentsGrid
        }
        ... on DatoCmsChecklistGrid {
          ...ChecklistGrid
        }
        ... on DatoCmsAvailableClinic {
          ...AvailableClinic
        }
        ... on DatoCmsPricing {
          ...Pricing
        }
        ... on DatoCmsAccordion {
          ...Accordion
        }
        ... on DatoCmsJotform {
          paddingTop
          paddingBottom
          src
          id
          __typename
        }
        ... on DatoCmsPill {
          ...Pill
        }
        ... on DatoCmsTestimonial {
          ...Testimonial
        }
      }
    }
  }
`
export default Page
